import { styled } from 'Theme/stitches.config';
import ColorVariants from 'DesignSystem/Typography/TextColorBase';

export const StyledButtonLabel = styled('span', {
  color: '$onSurface',
  fw: '$fontWeightRegular',
  textTransform: 'uppercase',
  fontFamily: 'fontSemiBold',
  textUnderlineOffset: '3px',
  letterSpacing: '.64px',

  variants: {
    size: {
      s: {
        fontSize: '$fontSizes$fontSize50',
        lineHeight: '$lh16',
      },
      m: {
        fontSize: '$fontSizes$fontSize100',
        lineHeight: '$lh20',
      },
    },
    hover: {
      true: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    color: ColorVariants,
  },
});
