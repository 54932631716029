import { styled } from 'Theme/stitches.config';
import { StyledButtonLabel } from 'DesignComponents/Atoms/Buttons/ButtonLabel/BaseButtonLabelStyle';
import ButtonLabel from 'DesignComponents/Atoms/Buttons/ButtonLabel/ButtonLabel';
import { BaseProps } from './BaseButtonType';
import { StitchesVariantProps } from 'Shared/Types/types';
import KexIconLoader, { IconName } from 'DesignSystem/Icons/KexIconLoader';

type StyledButtonVariants = StitchesVariantProps<typeof Root>;

export type SecondaryButtonType = BaseProps & {
  color: StyledButtonVariants['color'];
  icon?: IconName;
  inverse?: boolean;
  size?: StyledButtonVariants['size'];
  iconLocation?: 'left' | 'right';
};

function SecondaryButton({
  children,
  color = 'Regular',
  disabled,
  focus,
  pressed,
  size,
  text,
  inverse,
  icon,
  iconLocation,
  hug,
}: SecondaryButtonType) {
  const Icon = icon && KexIconLoader(icon);
  return (
    <Root
      color={color}
      size={size}
      hug={hug}
      {...(disabled && { disabled })}
      {...(pressed && { pressed })}
      {...(focus && { focus })}
      {...(inverse && { inverse })}
    >
      {children}
      <InnerWrapper iconLocation={iconLocation}>
        {Icon && (
          <Icon
            color={color === 'Inverse' ? 'inverse' : 'primary'}
            size={'s'}
          />
        )}
        <ButtonLabel size={size}>{text}</ButtonLabel>
      </InnerWrapper>
    </Root>
  );
}

const Root = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '48px',
  borderWidth: 0,
  $$borderColor: '$colors$interactiveBorderSecondaryDefault',
  $$borderWidth: '1px',
  '-webkit-box-shadow': 'inset 0px 0px 0px $$borderWidth $$borderColor',
  '-moz-box-shadow': 'inset 0px 0px 0px $$borderWidth $$borderColor',
  boxShadow: 'inset 0px 0px 0px $$borderWidth $$borderColor',
  cursor: 'pointer',
  w: 'fit-content',
  py: '$s90',
  px: '$s150',
  br: 1,
  '&:hover': {
    $$borderWidth: '2px',
  },
  '&:focus-visible, &:focus': {
    outline: 'unset !important',
  },
  variants: {
    color: {
      Regular: {
        [`& ${StyledButtonLabel}`]: {
          color: '$onInteractiveSecondary',
        },
      },
      Inverse: {
        $$borderColor: '$colors$interactiveInverse',
        [`& ${StyledButtonLabel}`]: {
          color: '$onInteractivePrimary',
        },
      },
    },
    size: {
      s: {
        p: '$s75',
        g: '$s50',
      },
      m: {},
    },
    disabled: {
      true: {
        backgroundColor: '$interactiveDisabled_1',
        color: '$interactiveDisabled_2',
        $$borderWidth: '1px',
        $$borderColor: 'unset',
      },
    },
    inverse: {
      true: {
        flexDirection: 'row-reverse',
      },
    },
    pressed: {
      true: {},
    },

    hug: {
      height: {
        h: '100%',
      },
      width: {
        w: '100%',
      },
    },
  },
  compoundVariants: [
    {
      disabled: true,
      color: 'Inverse',
      css: {
        color: '$interactiveDisabled_1',
      },
    },
    {
      pressed: true,
      color: 'Regular',
      css: {
        background: '$interactiveSecondaryBackgroundPressed',
        $$borderColor: '$colors$interactiveBorderSecondaryHover',
        '&:hover': {
          background: '$interactiveSecondaryBackgroundPressed',
          $$borderColor: '$colors$interactiveBorderSecondaryHover',
        },
      },
    },
    {
      pressed: true,
      color: 'Inverse',
      css: {
        background: '$interactivePrimaryBackgroundPressed',
        '&:hover': {
          background: '$interactivePrimaryBackgroundPressed',
        },
      },
    },
    {
      color: 'Inverse',
      css: {
        boxShadow: '0px 0px 0px 2px $colors$interactiveBorderActiveInverse',
      },
    },
    {
      hug: 'width',
      css: {
        justifyContent: 'center',
      },
    },
  ],
});

const InnerWrapper = styled('div', {
  display: 'flex',
  gap: '$s50',
  variants: {
    iconLocation: {
      right: {
        flexDirection: 'row-reverse',
      },
      left: {
        flexDirection: 'row',
      },
    },
  },
});

export default SecondaryButton;
