import { CSS } from '@stitches/react';
import { StyledButtonLabel } from './BaseButtonLabelStyle';
import type * as Stitches from '@stitches/react';

type StyledButtonVariants = Stitches.VariantProps<typeof StyledButtonLabel>;

type PropTypes = {
  children: React.ReactNode;
  size?: StyledButtonVariants['size'];
  css?: CSS;
  color?: StyledButtonVariants['color'];
};
const ButtonLabel = ({ children, size, css, color }: PropTypes) => {
  return (
    <StyledButtonLabel color={color} size={size} css={css}>
      {children}
    </StyledButtonLabel>
  );
};

export default ButtonLabel;
