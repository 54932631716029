const ColorVariants = {
  onSurface: {},
  onSurfaceSubtle: {},
  onSurfaceVariant: {},
  onInteractivePrimary: {},
  onInteractiveEmphasized: { color: '$onInteractiveEmphasized' },
  onInteractiveSecondary: { color: '$onInteractiveSecondary' },
  onInverse: {},
};

export default ColorVariants;
